import React from "react"
import Layout from "../components/Layout/layout"
import SEO from "../components/seo"
import HomeSlider from "../components/Slider/Slider";
import Card from "../components/Card/Card";
import CtaBanner from "../components/CtaBanner/CtaBanner"

const IndexPage = () => (
  <Layout>
    <SEO
      title="Heavy Fabrication, Machinery Installation, Removals & Precision Engineering Nationwide."
      description="CNR Engineering specialise in heavy fabrication, machinery installation & precision engineering nationwide, with a wide range of vehicles suitable for a variety of jobs."
    />
    <HomeSlider />
    <Card />
    <CtaBanner />
  </Layout>
)

export default IndexPage
