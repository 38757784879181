import React, { Component } from "react";
import { Link } from "gatsby"
import Slider from "react-slick";

import "./slider.scss"
import imageWagon from "../../assets/images/wagon.jpg"
import machineInstall from "../../assets/images/machine-installation-8.jpg"
import fabrication from "../../assets/images/fabrication.jpg"
import imageLoading from "../../assets/images/loading.jpg"

export default class HomeSlider extends Component {
  state = {
    activeSlide: 0,
    activeSlide2: 0,
    //images
    heroImage: ['', '', '', ''],
    // title
    heroTitle: ['Welcome to CNR Engineering', 'On Site Welding', 'Machinery Installation & Removal', 'Our Fleet'],
    activeHeroTitle: 'Welcome to CNR Engineering',
    // subtext
    subText: [
      "Specialists in heavy fabrication, machinery installation, removals & precision engineering nationwide.",
      "CNR Engineering are specialists when it comes to on-site welding. Click to view more.",
      "We specialise in the installation, removal and relocation of all kinds of industrial, manufacturing and processing machinery and equipment.",
      "CNR Engineering's fleet consists of a wide range of vehicles suitable for a variety of jobs. Click to view our fleet."
    ],
    activeSubText: 'Specialists in heavy fabrication, machinery installation, removals & precision engineering nationwide.',
    // url
    linkUrl: ['/services/general-fabrication/', '/services/on-site-welding/', '/services/machinery-installation-removal/', '/our-fleet'],
    activeLinkUrl: '/services/general-fabrication/',
  };

  // componentDidMount() {
  //   console.log(this.state.activeHeroTitle)
  // }

  render() {
    const settings = {
      dots: false,
      draggable: true,
      fade: true,
      infinite: true,
      speed: 600,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 3500,
      beforeChange: (current, next) => this.setState({ activeSlide: next }),
      afterChange: (current) => this.setState({ activeHeroTitle: this.state.heroTitle[current], activeSubText: this.state.subText[current], activeLinkUrl: this.state.linkUrl[current]})
    };
    return (
      <div className="hero" style={{position: "relative"}}>
        <div className="hero__overlay"></div>

        <Slider className="slick-slider__home" {...settings}>
          <div className="s_item">
            <img src={machineInstall} alt="Welding"/>
          </div>
          <div className="s_item">
            <img src={fabrication} alt="Welding"/>
          </div>
          <div className="s_item">
            <img src={imageLoading} alt="Equipment Hire"/>
          </div>
          <div className="s_item">
            <img src={imageWagon} alt="Wagon"/>
          </div>
        </Slider>

        <div className="hero__content hero__content--home">
          <div className="page-width">
            <div className="hero__content_box">
              <h2>{this.state.activeHeroTitle}</h2>
              <p>{this.state.activeSubText}</p>
            </div>
            {
              this.state.activeSlide === 0
              ?
              <>
                <Link to="/services" className="hero__button">
                  View services
                </Link>
                <Link to="/about" className="hero__button--outline">
                  Read more
                </Link>
              </>
              :
              <Link to={this.state.activeLinkUrl} className="hero__button">
                Read more
              </Link>
            }
          </div>
        </div>

      </div>
    );
  }
}
