import React from "react"
import { Link } from "gatsby"

import "./card.scss"

const Card = () => (
    <div className="card">
        <div className="page-width">
            <h2>Services</h2>
            <div className="card__grid">
                <Link to="/services/machinery-installation-removal/" className="card__item">
                    <svg className="card__svg-truck" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                        width="115" height="115" viewBox="0 0 70 70" enableBackground="new 0 0 70 70" >
                    <path d="M24.562,21.242l2.368-5.819h-2.563l-2.368,5.819H24.562z"/>
                    <path d="M28.342,21.242l2.38-5.819H28.16l-2.368,5.819H28.342z"/>
                    <path d="M37.909,47.56h0.045c-0.068-0.615-0.228-1.219-0.479-1.777l-0.033,0.023c-0.309,0.182-0.695,0.068-0.854-0.239
                        c-0.183-0.307-0.068-0.684,0.228-0.854l0.034-0.023c-0.365-0.489-0.797-0.934-1.299-1.298l-0.022,0.034
                        c-0.183,0.308-0.559,0.409-0.854,0.239c-0.307-0.183-0.41-0.558-0.238-0.854c0,0,0,0,0-0.011l0.022-0.035
                        c-0.57-0.25-1.162-0.421-1.777-0.479v0.046c0,0.354-0.273,0.626-0.626,0.626c-0.354,0-0.627-0.272-0.627-0.626v-0.046
                        c-0.615,0.068-1.218,0.229-1.776,0.479l0.022,0.035c0.183,0.308,0.08,0.683-0.227,0.853c-0.308,0.172-0.683,0.081-0.854-0.227
                        l-0.022-0.034c-0.49,0.364-0.934,0.798-1.299,1.298l0.035,0.023c0.307,0.182,0.41,0.558,0.24,0.854
                        c-0.183,0.308-0.559,0.409-0.855,0.239c0,0,0,0-0.011,0l-0.034-0.023c-0.25,0.57-0.422,1.162-0.478,1.777h0.045
                        c0.354,0,0.627,0.273,0.627,0.626c0,0.354-0.273,0.627-0.627,0.627H26.11c0.068,0.614,0.228,1.218,0.478,1.775l0.035-0.022
                        c0.307-0.181,0.683-0.079,0.853,0.229c0.183,0.307,0.081,0.684-0.228,0.854c0,0,0,0-0.011,0l-0.035,0.022
                        c0.365,0.49,0.797,0.934,1.299,1.299l0.022-0.033c0.183-0.309,0.558-0.41,0.854-0.229c0.308,0.183,0.41,0.559,0.228,0.854
                        l-0.022,0.034c0.57,0.251,1.161,0.422,1.777,0.479v-0.023c0-0.354,0.273-0.626,0.626-0.626s0.626,0.272,0.626,0.626v0.046
                        c0.616-0.068,1.218-0.228,1.777-0.479l-0.022-0.035c-0.183-0.307-0.081-0.683,0.228-0.853c0.308-0.172,0.684-0.081,0.854,0.227
                        v0.012l0.023,0.034c0.489-0.364,0.934-0.797,1.299-1.299l-0.035-0.022c-0.308-0.182-0.41-0.558-0.238-0.854
                        c0.182-0.309,0.558-0.41,0.854-0.24h0.011l0.035,0.023c0.25-0.569,0.421-1.161,0.478-1.776h-0.046c-0.353,0-0.626-0.273-0.626-0.626
                        C37.202,47.854,37.555,47.56,37.909,47.56L37.909,47.56z M32.044,51.932c-2.073,0-3.747-1.685-3.747-3.746
                        c0-2.072,1.685-3.747,3.747-3.747s3.747,1.675,3.747,3.747C35.791,50.247,34.105,51.932,32.044,51.932z"/>
                    <circle cx="32.044" cy="48.186" r="2.483"/>
                    <path d="M28.82,29.19v5.763h6.299v-4.965c0-0.445-0.354-0.797-0.798-0.797H28.82z"/>
                    <path d="M20.018,36.092h2.392v2.983h-2.392V36.092z"/>
                    <path d="M27.682,29.179h-3.758c-0.296,0.011-0.569,0.171-0.707,0.433l-2.824,5.329h7.289V29.179z"/>
                    <path d="M6.045,27.129c0.147-0.273,0.045-0.626-0.228-0.773c-0.273-0.149-0.626-0.046-0.775,0.227
                        c-0.331,0.604-1.082,0.832-1.696,0.5c-0.604-0.329-0.832-1.082-0.501-1.696c0.183-0.331,0.501-0.558,0.866-0.627
                        c0.467-0.09,0.808-0.5,0.796-0.968v-3.564h0.559V19.18h4.908v1.048h10.25v1.014h0.546l2.368-5.819h-2.915v0.99H9.973v1.037H5.065
                        v-1.037H2.731v3.815h0.648v3.451c-1.274,0.318-2.06,1.605-1.753,2.892c0.25,1.036,1.161,1.776,2.22,1.811h0.092
                        C4.826,28.382,5.634,27.904,6.045,27.129L6.045,27.129z"/>
                    <path d="M39.707,21.242l2.381-5.819h-2.563l-2.368,5.819H39.707z"/>
                    <path d="M43.306,21.23v-0.854c0-1.116,0.627-2.13,1.617-2.642l0.945-2.323h-2.563l-2.368,5.819H43.306z"/>
                    <path d="M48.453,18.383l1.207-2.96h-2.562l-0.82,2.004C47.109,17.427,47.896,17.779,48.453,18.383z"/>
                    <path d="M44.444,20.376v13.233c0,1.002,0.821,1.822,1.823,1.822c1.001,0,1.822-0.819,1.822-1.822V20.376
                        c0-1.002-0.821-1.822-1.822-1.822C45.266,18.565,44.444,19.374,44.444,20.376z"/>
                    <path d="M35.928,21.242l2.367-5.819h-2.55l-2.381,5.819H35.928z"/>
                    <path d="M32.135,21.242l2.379-5.819h-2.562l-2.381,5.819H32.135z"/>
                    <path d="M49.229,20.376v0.866h1.229c0.581,0,1.048-0.467,1.048-1.048V16.47c0-0.421-0.263-0.808-0.649-0.967l-1.72,4.202
                        C49.193,19.921,49.229,20.148,49.229,20.376L49.229,20.376z"/>
                    <path d="M59.022,45.464c-1.367,0-2.483,1.117-2.483,2.482c0,1.366,1.116,2.482,2.483,2.482c1.366,0,2.482-1.116,2.482-2.482
                        C61.505,46.581,60.389,45.486,59.022,45.464z"/>
                    <path d="M64.888,47.332h0.045c-0.068-0.616-0.228-1.219-0.479-1.777l-0.033,0.022c-0.309,0.183-0.695,0.069-0.855-0.238
                        c-0.182-0.308-0.067-0.683,0.228-0.854l0.034-0.022c-0.364-0.489-0.796-0.934-1.298-1.299l-0.022,0.035
                        c-0.183,0.308-0.559,0.409-0.854,0.238c-0.307-0.182-0.409-0.558-0.239-0.854c0,0,0,0,0-0.011l0.023-0.035
                        c-0.57-0.251-1.162-0.421-1.777-0.478v0.046c0,0.352-0.273,0.626-0.626,0.626s-0.627-0.274-0.627-0.626V42.06
                        c-0.614,0.068-1.218,0.227-1.775,0.478l0.022,0.035c0.183,0.307,0.079,0.683-0.228,0.854c-0.308,0.171-0.685,0.08-0.854-0.228
                        c0,0,0,0,0-0.013l-0.023-0.033c-0.489,0.365-0.934,0.797-1.298,1.299l0.035,0.022c0.307,0.183,0.409,0.558,0.238,0.854
                        c-0.183,0.307-0.558,0.409-0.854,0.238c0,0,0,0-0.011,0l-0.034-0.022c-0.251,0.569-0.422,1.161-0.478,1.777h0.044
                        c0.354,0,0.627,0.272,0.627,0.625c0,0.354-0.272,0.626-0.627,0.626h-0.09c0.067,0.616,0.227,1.219,0.478,1.777l0.035-0.022
                        c0.307-0.183,0.683-0.08,0.854,0.228c0.17,0.308,0.079,0.683-0.229,0.854c0,0,0,0-0.012,0l-0.034,0.022
                        c0.364,0.489,0.797,0.934,1.299,1.299l0.022-0.035c0.182-0.308,0.558-0.41,0.854-0.239c0.308,0.183,0.41,0.559,0.24,0.854v0.011
                        l-0.023,0.035c0.569,0.249,1.162,0.421,1.775,0.478v-0.046c0-0.353,0.274-0.626,0.627-0.626s0.626,0.273,0.626,0.626v0.046
                        c0.616-0.068,1.22-0.229,1.777-0.478l-0.022-0.035c-0.183-0.307-0.079-0.683,0.228-0.854s0.684-0.08,0.854,0.228v0.012l0.021,0.034
                        c0.491-0.365,0.935-0.797,1.3-1.299l-0.035-0.022c-0.307-0.183-0.41-0.558-0.238-0.854c0.183-0.308,0.558-0.409,0.853-0.238h0.013
                        l0.033,0.022c0.251-0.57,0.422-1.161,0.479-1.777H64.83c-0.354,0-0.627-0.272-0.627-0.626
                        C64.238,47.594,64.533,47.332,64.888,47.332z M59.022,51.704c-2.074,0-3.748-1.685-3.748-3.746c0-2.061,1.687-3.747,3.748-3.747
                        s3.746,1.675,3.746,3.747C62.769,50.02,61.084,51.704,59.022,51.704z"/>
                    <path d="M39.286,34.953l-4.271,5.968l8.085,0.011"/>
                    <path d="M38.584,35.934l3.526,5.154l5.277,0.322l3.754-0.479v-8.838c0-0.5-0.41-0.91-0.911-0.91h-1.015v2.368
                        c0,1.617-1.263,2.973-2.881,3.018c-1.674,0.046-3.04-1.298-3.04-2.96v-2.425H42.44c-0.296,0-0.57,0.136-0.74,0.375l-1.742,2.438"/>
                    <path d="M57.701,39.564c-1.139,0.172-2.244,0.547-3.233,1.129L36.53,40.659c-0.455-0.263-0.922-0.479-1.411-0.661V36.08H23.56v3.553
                        c0,0.319-0.25,0.57-0.57,0.57h-2.96v5.125h-0.467c-0.251,0-0.456,0.204-0.456,0.454v1.823c0,0.249,0.205,0.455,0.456,0.455h3.438
                        c-0.011,0.137-0.011,0.285-0.011,0.422c0,0.295,0.216,0.568,0.513,0.592c0.342,0.033,0.626-0.239,0.626-0.568
                        c0-4.374,3.542-7.915,7.916-7.928c4.372-0.011,7.914,3.541,7.925,7.915c0,0.239,0.149,0.467,0.377,0.535
                        c0.398,0.125,0.763-0.159,0.763-0.535c0-0.649-0.067-1.298-0.205-1.936h0.956v-2.084c0-0.57,0.456-1.024,1.025-1.024h5.284
                        c0.57,0,1.024,0.454,1.024,1.024v2.084h0.979c-0.137,0.625-0.204,1.264-0.204,1.902c0,0.307,0.216,0.568,0.513,0.602
                        c0.341,0.035,0.626-0.238,0.626-0.568c-0.022-4.476,3.689-8.107,8.211-7.948c4.235,0.148,7.606,3.633,7.629,7.869v0.057
                        c0,0.296,0.217,0.558,0.514,0.58c0.341,0.035,0.625-0.228,0.625-0.568c0-0.628-0.067-1.265-0.204-1.891h1.662
                        c0.251,0,0.456-0.205,0.456-0.456v-2.402c0-0.251-0.205-0.456-0.456-0.456l0,0h-2.722v-2.562L63.6,40.705
                        c-1.002-0.582-2.095-0.98-3.245-1.151"/>
                    <path d="M47.145,44.621h-3.235c-0.5,0-0.91,0.41-0.91,0.911v1.743c0,0.501,0.41,0.91,0.91,0.91h3.235c0.501,0,0.91-0.409,0.91-0.91
                        v-1.743C48.055,45.031,47.646,44.621,47.145,44.621z"/>
                    </svg>
                    <p>Machinery Installation & Removal</p>
                    <span className="card__rm">Learn more</span>
                </Link>
                <Link to="/services/general-fabrication/" className="card__item">
                    <svg className="card__svg" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="70px" height="70px" viewBox="0 0 48 48" enableBackground="new 0 0 48 48">
                        <g id="welder">
                            <path d="M36.897,9.058c0.261,0.682-0.081,1.445-0.763,1.704l-0.19,0.072c-0.683,0.261-1.444-0.081-1.705-0.761l-0.072-0.19
                                c-0.259-0.682,0.082-1.445,0.763-1.707l0.191-0.07c0.681-0.26,1.443,0.081,1.704,0.762L36.897,9.058z"/>
                            <path d="M29.706,26.188c1.333-3.903,4.631-6.038,8.397-6.814c1.859-0.385,4.156-0.668,5.556,1.078
                                c1.544,1.931,2.07,4.779,2.743,7.199c0.76,2.731,1.412,5.851,1.547,8.722c0.096,2.001,0.369,5.676-1.571,6.829l-28.221-0.18
                                c-1.018-1.152-1.566-4.048-0.277-5.585c1.685-2.008,4.802-2.118,7.049-2.348c1.085-0.111,2.634,0.059,3.464-0.927
                                c1.082-1.284,0.592-4.115,0.803-5.714C29.3,27.65,29.474,26.873,29.706,26.188z"/>
                            <path d="M37.386,15.397c-0.286-1.842-0.362-3.921-1.284-5.578c-1.951-3.502-3.624-6.069-7.997-5.833
                                c-1.986,0.109-3.691,0.934-5.432,1.803c-7.103,3.556-3.702,8.507-3.702,8.507l5.688-2.693l2.542,4.262l-5.287,2.602l4.957,9.293
                                l2.312-0.611c3.33-0.878,7.313-1.348,8.237-5.278C37.921,19.744,37.715,17.532,37.386,15.397z M31.282,9.642
                                c-0.626,0-1.135-0.507-1.135-1.133c0-0.626,0.509-1.135,1.135-1.135s1.135,0.509,1.135,1.135
                                C32.417,9.135,31.908,9.642,31.282,9.642z"/>
                            <g>
                                <polygon points="14.926,30.979 25.544,39.171 27.034,37.24 16.416,29.049 15.67,30.015"/>
                                <polygon points="11.238,27.111 16.388,31.079 17.132,30.115 11.983,26.143 11.61,26.627"/>
                                <polygon points="5.937,28.265 12.385,27.433 12.229,26.223 5.781,27.054 5.858,27.659"/>
                                <line fill="none" stroke="#1a4870" strokeWidth="0.4064" strokeMiterlimit="10" strokeDasharray="0.4064" x1="1.813" y1="24.615" x2="5.188" y2="26.025"/>
                                <line fill="none" stroke="#1a4870" strokeWidth="0.4064" strokeMiterlimit="10" strokeDasharray="0.4064" x1="0.873" y1="27.979" x2="4.503" y2="27.538"/>
                                <line fill="none" stroke="#1a4870" strokeWidth="0.4064" strokeMiterlimit="10" strokeDasharray="0.4064" x1="1.929" y1="30.725" x2="5.072" y2="28.855"/>
                            </g>
                        </g>
                    </svg>
                    <p>Fabrication</p>
                    <span className="card__rm">Learn more</span>
                </Link>
                <Link to="/services/" className="card__item">
                    <svg className="card__svg" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="70px"
                        height="70px" viewBox="0 0 70 70" enableBackground="new 0 0 70 70">
                    <g id="Layer_2" display="none">
                        <rect display="inline" width="70" height="70"/>
                    </g>
                    <g id="Layer_1">
                        <path d="M56.011,27.891l-0.896,1.564c-0.328,0.58-1.028,0.82-1.651,0.591c-1.291-0.481-2.473-1.17-3.512-2.035
                            c-0.503-0.416-0.634-1.148-0.306-1.717l0.896-1.564c-0.755-0.875-1.345-1.892-1.739-2.997h-1.805c-0.656,0-1.225-0.47-1.334-1.126
                            c-0.219-1.313-0.229-2.691,0-4.058c0.109-0.656,0.678-1.137,1.334-1.137h1.805c0.395-1.105,0.984-2.122,1.739-2.997l-0.896-1.564
                            c-0.328-0.569-0.208-1.302,0.306-1.717c1.039-0.864,2.231-1.553,3.512-2.034c0.623-0.23,1.323,0.011,1.651,0.59l0.896,1.564
                            c1.148-0.208,2.319-0.208,3.468,0l0.896-1.564c0.328-0.58,1.028-0.82,1.651-0.59c1.291,0.481,2.472,1.17,3.511,2.034
                            c0.504,0.416,0.635,1.148,0.307,1.717l-0.896,1.564c0.754,0.875,1.345,1.892,1.738,2.997h1.805c0.656,0,1.226,0.47,1.335,1.126
                            c0.219,1.313,0.229,2.69,0,4.058c-0.109,0.656-0.679,1.138-1.335,1.138h-1.805c-0.394,1.104-0.984,2.122-1.738,2.997l0.896,1.564
                            c0.328,0.568,0.208,1.301-0.307,1.717c-1.039,0.864-2.23,1.553-3.511,2.035c-0.623,0.229-1.323-0.011-1.651-0.591l-0.896-1.564
                            C58.341,28.099,57.159,28.099,56.011,27.891z M54.862,21.459c4.211,3.237,9.013-1.564,5.775-5.775
                            C56.427,12.436,51.625,17.249,54.862,21.459z M42.252,38.292l3.686,1.838c1.104,0.634,1.586,1.979,1.148,3.183
                            c-0.974,2.646-2.888,5.075-4.659,7.197c-0.81,0.973-2.21,1.214-3.314,0.579l-3.183-1.837c-1.75,1.498-3.784,2.69-6.005,3.467v3.675
                            c0,1.269-0.908,2.362-2.154,2.581c-2.691,0.46-5.513,0.481-8.302,0c-1.258-0.219-2.188-1.301-2.188-2.581v-3.675
                            c-2.22-0.787-4.255-1.969-6.005-3.467l-3.183,1.826C7,51.713,5.589,51.472,4.78,50.498c-1.772-2.121-3.642-4.55-4.616-7.186
                            c-0.438-1.192,0.044-2.537,1.148-3.183l3.642-1.838c-0.426-2.286-0.426-4.637,0-6.934l-3.642-1.849
                            c-1.104-0.634-1.597-1.979-1.148-3.172c0.974-2.647,2.844-5.075,4.616-7.197c0.809-0.974,2.209-1.214,3.314-0.58l3.183,1.837
                            c1.75-1.499,3.785-2.69,6.005-3.467v-3.686c0-1.258,0.897-2.352,2.144-2.57c2.691-0.459,5.523-0.481,8.313-0.011
                            c1.258,0.219,2.188,1.302,2.188,2.581v3.675c2.221,0.788,4.255,1.969,6.005,3.467l3.183-1.838c1.094-0.634,2.505-0.394,3.314,0.58
                            c1.771,2.122,3.631,4.55,4.604,7.197c0.438,1.192,0.011,2.538-1.094,3.183l-3.686,1.837C42.678,33.644,42.678,35.995,42.252,38.292
                            z M29.389,40.6c6.475-8.421-3.139-18.036-11.561-11.561C11.353,37.461,20.967,47.075,29.389,40.6z M56.011,60.583l-0.896,1.563
                            c-0.328,0.58-1.028,0.82-1.651,0.591c-1.291-0.481-2.473-1.17-3.512-2.034c-0.503-0.416-0.634-1.148-0.306-1.717l0.896-1.564
                            c-0.755-0.875-1.345-1.893-1.739-2.997h-1.805c-0.656,0-1.225-0.47-1.334-1.126c-0.219-1.313-0.229-2.691,0-4.059
                            c0.109-0.656,0.678-1.137,1.334-1.137h1.805c0.395-1.105,0.984-2.122,1.739-2.997l-0.896-1.564
                            c-0.328-0.568-0.208-1.302,0.306-1.717c1.039-0.864,2.231-1.554,3.512-2.034c0.623-0.23,1.323,0.011,1.651,0.59l0.896,1.564
                            c1.148-0.208,2.319-0.208,3.468,0l0.896-1.564c0.328-0.579,1.028-0.82,1.651-0.59c1.291,0.48,2.472,1.17,3.511,2.034
                            c0.504,0.415,0.635,1.148,0.307,1.717l-0.896,1.564c0.754,0.875,1.345,1.892,1.738,2.997h1.805c0.656,0,1.226,0.47,1.335,1.126
                            c0.219,1.313,0.229,2.69,0,4.058c-0.109,0.656-0.679,1.138-1.335,1.138h-1.805c-0.394,1.104-0.984,2.122-1.738,2.997l0.896,1.564
                            c0.328,0.568,0.208,1.301-0.307,1.717c-1.039,0.864-2.23,1.553-3.511,2.034c-0.623,0.229-1.323-0.011-1.651-0.591l-0.896-1.563
                            C58.341,60.791,57.159,60.791,56.011,60.583z M54.862,54.141c4.211,3.237,9.013-1.564,5.775-5.775
                            C56.427,45.128,51.625,49.93,54.862,54.141z"/>
                    </g>
                    </svg>
                    <p>Precision Engineering</p>
                    <span className="card__rm">Learn more</span>
                </Link>
            </div>
            <p className="card__link">We also offer a full <a href="https://cnrmetrology.co.uk">Mobile CMM Inspection Service</a> across the United Kingdom.</p>
            <Link to="/services/" className="button--brand button card__button">View all services</Link>
        </div>
    </div>
)

export default Card
